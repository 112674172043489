@tailwind base;
@tailwind components;
@tailwind utilities;

img {
    cursor: pointer;
}

@font-face {
    font-family: 'Gistesy';
    src: url('../assets/fonts/Gistesy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

::selection {
    background-color: #50af8a;
    color: #000000;
}

::-webkit-scrollbar {
    width: 20px
}

::-webkit-scrollbar-track {
    background: #474642;
}

::-webkit-scrollbar-thumb {
    background-color: #50af8a;
}

::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
}